.App {
  text-align: center;

}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {.fillScreen {
  min-height: -webkit-fill-available;
  }}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {.fillScreen {
  min-height: -webkit-fill-available;
  }}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {.fillScreen {
    height: 1080px;
  }}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {.fillScreen {
    height: 1080px;
  }}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {.fillScreen {
    height: 1080px;
  }}




.fillScreen{

  box-shadow: 20px 3px 20px 0px;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
